<template>
  <div class="custom-upload">
    <el-upload
      ref="upload"
      :class="{ 'hide-upload-plus': fileList && fileList.length >= uploadLimit }"
      :action="$baseUpload"
      :headers="uploadHeaders"
      list-type="picture-card"
      multiple
      :limit="uploadLimit"
      :file-list="fileList"
      :before-upload="beforeUpload"
      :on-success="successUpload"
      :on-remove="fileRemove"
      :on-preview="pictureCardPreview"
      :disabled="formDisabled"
    >
      <i class="el-icon-plus" />
      <template #tip>
        <div class="el-upload__tip">
          提示：平台自动为您生成一张默认商品图片在前台进行展示.。您也可以删除默认图片, 上传其他更个性化的商品图片最多可上传5张，图片格式为jpg、png, 单张不超过5M, 建议您上传750*750像素以上的实拍大图
        </div>
      </template>
    </el-upload>
    <el-dialog
      :visible.sync="dialogVisible"
      title="预览"
      width="40%"
    >
      <img class="preview" fit="contain" :src="dialogImageUrl" alt="">
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'ImageUpload',
  props: {
    // 最多可上传多少条
    uploadLimit: {
      type: Number,
      default: 5
    },
    // 文件列表
    uploadFileList: {
      default: () => [],
      type: Array
    },
    // 是否预览
    preview: Boolean,
    formDisabled: Boolean
  },
  data() {
    return {
      dialogVisible: false,
      dialogImageUrl: '',
      // 附件上传请求头
      uploadHeaders: {
        token: ''
      },
      // 附件列表
      fileList: []
    }
  },
  watch: {
    uploadFileList: {
      handler(files) {
        this.fileList = JSON.parse(JSON.stringify(files || [])).map(item => {
          if (item.url.indexOf('http://') !== -1) return item
          item.url = this.$fileUrl + item.url
          return item
        })
      },
      immediate: true,
      deep: true
    }
  },
  created() {
    this.uploadHeaders.token = localStorage.getItem('logintoken')
  },
  methods: {
    beforeUpload(file) {
      const suffix = file.name.split('.').pop()
      const isAllowFormat = /jpg|jpeg|png|gif|tif|psd|bmp/i.test(
        suffix
      )
      const isLt5M = file.size / 1024 / 1024 < 5
      if (!isAllowFormat) {
        this.$message.warning(
          '上传的只能是 JPG、JPEG、GIF、PNG、GIF、TIF、PSD、BMP 格式'
        )
        return false
      }
      if (!isLt5M) {
        this.$message.warning('图片大小不能超过 5M')
        return false
      }
    },
    successUpload(res, file) {
      if (res.code === 200) {
        this.$message.success(res.msg)
        this.fileList.push({
          url: res.data,
          name: file.name
        })
        this.$emit('update:uploadFileList', this.fileList)
        return
      }
      this.$refs.upload.clearFiles()
      this.$message.warning('文件上传失败！')
    },
    // 删除
    fileRemove(file) {
      this.fileList.forEach((item, index) => {
        if (item.url === file.response.data) this.fileList.splice(index, 1)
      })
      this.$emit('update:uploadFileList', this.fileList)
    },
    // 预览
    async pictureCardPreview(file) {
      this.dialogImageUrl = file.url
      this.dialogVisible = true
    }
  }
}
</script>

<style scoped lang="scss">
/deep/ .el-upload.el-upload--picture-card {
  display: none !important;
}
.preview {
  width: 100%;
}
</style>
